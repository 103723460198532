<template>
  <v-container fluid fill-height style="background-color: #336799"
               class="d-flex flex-column flex-nowrap align-center justify-center">

    <!-- Logo -->
    <LogoText id="login-logo" :fill="'#ffffff'" @click.native="goToHomePage()"/>

    <!-- Form -->
    <div id="login-form" v-if="!forgot && $route.query.token == null">
      <v-text-field class="login-field" type="text" :label="$t('login.login-or-email')" id="login"
                    outlined hide-details v-model="login" @keyup.enter="focusPassword()"/>
      <v-text-field class="login-field" type="password" :label="$t('login.password')" id="password"
                    outlined hide-details v-model="password" @keyup.enter="tryToLogin()"/>
      <v-btn id="login-button" color="primary lighten-1" @click="tryToLogin()" :disabled="lock">
        {{ $t('login.login') }}
      </v-btn>
    </div>
    <div id="login-form" v-else-if="forgot && $route.query.token == null">
      <v-text-field class="login-field" type="text" :label="$t('login.email')" id="email"
                    outlined hide-details v-model="email" @keyup.enter="tryToSendRequest()"/>
      <v-text-field class="login-field" type="text" outlined hide-details style="visibility: hidden"/>
      <v-btn id="login-button" color="primary lighten-1" @click="tryToSendRequest()" :disabled="lock">
        {{ $t('login.reset-password') }}
      </v-btn>
    </div>
    <div id="login-form" v-else>
      <v-text-field class="login-field" type="password" :label="$t('login.password')" id="password-reset"
                    outlined hide-details v-model="resetPassword" @keyup.enter="focusPasswordRepeat()"/>
      <v-text-field class="login-field" type="password" :label="$t('login.password-repeat')" id="password-reset-repeat"
                    outlined hide-details v-model="resetPasswordConfirm" @keyup.enter="tryToSetPassword()"/>
      <v-btn id="login-button" color="primary lighten-1" @click="tryToSetPassword()" :disabled="lock">
        {{ $t('login.set-password') }}
      </v-btn>
    </div>

    <!-- Footer -->
    <div id="login-footer" v-if="!forgot && $route.query.token == null" @click="forgot = true">
      {{ $t('login.forgot') }}
    </div>
    <div id="login-footer" v-else-if="forgot && $route.query.token == null" @click="forgot = false">
      {{ $t('login.login-instead') }}
    </div>
    <div id="login-footer" v-else>
      &nbsp;
    </div>

  </v-container>
</template>

<script>
import LogoText from '../assets/LogoText';

export default {
  name: 'Login',
  components: {LogoText},
  data: () => ({
    login: '', password: '', email: '',
    resetPassword: '', resetPasswordConfirm: '',
    forgot: false, lock: false
  }),
  methods: {
    goToHomePage() {
      window.location.href = this.$url;
    },
    focusPassword() {
      document.getElementById('password').focus();
    },
    focusPasswordRepeat() {
      document.getElementById('password-reset-repeat').focus();
    },
    tryToLogin() {
      if (this.lock) return;
      this.lock = true;
      this.$api.login(this.login, this.password).then(() => {
        window.location.href = '/app';
      }).catch((err) => {
        if (err.request && err.request.status === 400) {
          this.$store.commit('showSnackbar', this.$t('login.error'));
        } else this.$store.commit('showSnackbar', this.$t('common.error'));
      }).finally(() => {
        this.lock = false;
      });
    },
    tryToSendRequest() {
      if (this.lock) return;
      this.lock = true;
      this.$api.resetMyPassword(this.email).then(() => {
        this.$store.commit('showSnackbar', this.$t('login.reset-password-success'));
      }).catch((err) => {
        if (err.request && err.request.status === 400) {
          this.$store.commit('showSnackbar', this.$t('login.error-invalid-email'));
        } else this.$store.commit('showSnackbar', this.$t('common.error'));
      }).finally(() => {
        this.lock = false;
      });
    },
    tryToSetPassword() {
      if (this.lock) return;
      if (this.resetPassword !== this.resetPasswordConfirm)
        return this.$store.commit('showSnackbar', this.$t('login.error-passwords-not-match'));
      this.lock = true;
      this.$api.setMyPassword(this.resetPassword, this.$route.query.token).then(() => {
        this.$store.commit('showSnackbar', this.$t('login.set-password-success'));
        setTimeout(() => {
          window.location.href = '/app/login';
        }, 2000);
      }).catch((err) => {
        if (err.request && err.request.status === 400) {
          this.$store.commit('showSnackbar', this.$t('login.error-invalid-password'));
        } else if (err.request && err.request.status === 403) {
          this.$store.commit('showSnackbar', this.$t('login.error-token-expired'));
        } else this.$store.commit('showSnackbar', this.$t('common.error'));
      }).finally(() => {
        this.lock = false;
      });
    }
  }
}
</script>

<style scoped>
#login-logo {
  cursor: pointer;
  margin: 12px 8px;
  width: 200px;
}

#login-form {
  background-color: #ffffff;
  border-radius: 8px;
  height: 276px;
  margin: 10px 10px;
  width: 340px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.4);
}

.login-field {
  margin: 25px 25px 0 25px;
}

#login-button {
  color: #ffffff;
  height: 40px;
  margin: 30px 65px 0 65px;
  width: 200px;
}

#login-footer {
  color: #ffffff;
  cursor: pointer;
  letter-spacing: 1px;
  margin: 12px 8px;
  text-align: center;
  width: 360px;
}

#login-footer:hover {
  text-decoration: underline;
}
</style>
